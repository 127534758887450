import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
    faCircleExclamation,
    faCircleDot,
    faClockRotateLeft,
    faCodeFork,
    faEllipsis,
    faHandSparkles,
    faQuestion,
    faRefresh,
    faRightFromBracket,
    faScaleBalanced,
    faStar,
    faTrashCan,
    faWandMagicSparkles
} from '@fortawesome/free-solid-svg-icons'

library.add(
    faCircleExclamation,
    faCircleDot,
    faClockRotateLeft,
    faCodeFork,
    faEllipsis,
    faHandSparkles,
    faQuestion,
    faRefresh,
    faRightFromBracket,
    faScaleBalanced,
    faStar,
    faTrashCan,
    faWandMagicSparkles
)

export default FontAwesomeIcon
